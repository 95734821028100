<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status','today_report']"
            ref="list"
            :submit_preprocessing="submit_preprocessing"
        >
			<template slot="right_btn">
                <a :href="'/manage/crm.export/daily' | export_form" v-if="this.$store.state.page_auth.export" target="_blank">
					<a-button  class="a_btn">导出</a-button>
				</a>
            </template>
            <template slot="today_report" slot-scope="data">
                <span v-if="data.text" v-text="data.text.length <= 10 ? data.text : data.text.slice(0,10) + '...'"></span>
            </template>
            <template slot="status" slot-scope="data">
                <span v-for="item in status_list" :key="item.key">
                    <a-tag
                        :color="item.key == 1 ? config.color.warning : item.key == 2 ? config.color.error : config.color.primary"
                        v-if="data.text == item.key"
                    >{{item.value}}</a-tag>
                </span>
            </template>
            <template slot="operation" slot-scope="data">
                <a @click="get_details(data.record,data.index)">查看详情</a>
            </template>
        </TableList>

        <RightDrawer title :visible.sync="drawer_visible">
            <template slot="content">
                <UserHeader
                    :head_img="details.headimg"
                    :name="details.username"
                    :info="details.create_time"
                ></UserHeader>
                <a-divider />
                <a-descriptions>
                    <a-descriptions-item :span="3" label="部门">{{details.department}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="岗位职务">{{details.role}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="日报时间">{{details.date_time}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="今日工作汇报">{{details.today_report}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="明日工作计划">{{details.tomorrow_plan}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="遇到的问题">{{details.question}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="图片"></a-descriptions-item>
                </a-descriptions>
                <LookImages :list="details.images"></LookImages>
                <a-divider />

				<!-- <div v-for="item in details.work_record" :key="item">
					<span>{{item}}</span>
				</div> -->
                
                <div>
                    <div style="margin-bottom:15px;color:#666666;">工作记录</div>
                    <div class="clear">
                        <div class="num_box" v-for="(item,index) in details.work_record.census" :key="index">
                            <div>
                                <div style="zoom:0.8;">{{ item.name }}</div>
                                <div style="color:#000000;font-size:16px;">{{ item.value }}</div>
                                <div style="color:#888888;zoom:0.7;">{{ item.relatively_name }} <span :class="item.compare < 0 ? 'cgreen' : 'cred'">{{ item.compare > 0 ? '+' + item.compare : item.compare }}</span></div>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top:5px;">
                        <div style="display:flex;" v-for="(item,index) in details.work_record.goods_data" :key="index">
                            {{ item.name }}<div style="color:#000000;margin-left:3px;">{{ item.value }}</div>
                        </div>
                    </div>
                </div>
               
                <a-divider />
                <a-list class="comment-list" itemLayout="horizontal" :dataSource="details.approval">
                    <a-list-item slot="renderItem" slot-scope="item">
                        <a-comment :author="item.username" :src="item.headimg">
                            <p slot="content">{{item.content}}</p>
                            <span slot="datetime">{{item.create_time}}</span>
                        </a-comment>
                    </a-list-item>
                </a-list>
            </template>
        </RightDrawer>
    </div>
</template>

<script>

import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import RightDrawer from "@/components/RightDrawer";
import UserHeader from "@/components/UserHeader";
import LookImages from "@/components/LookImages";

import { dailyList, dailyInfo } from "@/api/attendance";

const columns = [
    {
        title: "日报日期",
        dataIndex: "date_time",
    },
    {
        title: "姓名",
        dataIndex: "username",
    },
    {
        title: "部门",
        dataIndex: "department",
    },
    {
        title: "内容",
        dataIndex: "today_report",
        scopedSlots: {
            customRender: "today_report"
        }
    },
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "直系领导",
        dataIndex: "leader",
    },
    {
        title: "创建日期",
        dataIndex: "create_time",
        width: '200px'
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

let status_list = [
    {
        key: 1,
        value: '待批示'
    },
    {
        key: 2,
        value: '已阅'
    },
    {
        key: 3,
        value: '已批示'
    }
]

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList,
        RightDrawer,
        UserHeader,
        LookImages
    },
    data() {
        return {
			get_table_list: dailyList,
			config:this.$config,
            submit_preprocessing: {
                array_to_string: ['department']
            },
            details: {
                goods: [],
                work_record:{
                    census:[],
                    goods_data:[],
                }
            },
            columns,
            status_list,
            drawer_visible: false,
            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "text",
                        name: "keyword",
                        title: "姓名",
                        placeholder: '姓名/账号/工号/手机号',
                        mode: "default",
                        options: {},
                        list: []
					},
					{
                        type: "select",
                        name: "status",
                        title: "状态",
                        options: {},
                        list: [
							...this.$config.status_all,
							...status_list,
						]
					},
					{
                        type: "select",
                        name: "user_status",
                        title: "在职状态",
                        mode: "default",
                        options: {},
                        list: this.$config.user_status_list
                    },
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "创建日期",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
            },
            form_data: {}
        };
    },
    async created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
        });
    },
    methods: {
        get_details(item, index) {
            dailyInfo({
                data: {
                    id: item.id
                }
            }).then(res => {
                this.details = res.data;
                this.show();
            })
        },
        show() {
            this.drawer_visible = true;
        },
        drawer_visible_close() {
            this.drawer_visible = false;
        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");

.clear:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    font-size: 0;
}

.num_box{
    width:33.33%;
    float:left;
    text-align: center;
    margin-bottom:15px;
}

.cred{
    color:red;
}
.cgreen{
    color:green;
}
</style>